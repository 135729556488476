import React from "react"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"

import FORM_URL from "../secret/env.secret.js"

export default class MyForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
    }
  }

  render() {
    const { status } = this.state
    const placeholerMsg="Ecrivez votre message ici, je vous répondrai dès que possible 😊\n\nNadine"
    return (
      <form onSubmit={this.submitForm} action={FORM_URL} method="POST">
        <Row className="mt-4">
          <Col sm className="p-2">
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Votre nom ou pseudo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ex : Nadine C."
                required
                name="pseudo"
              />
            </Form.Group>
          </Col>
          <Col sm className="p-2">
            <Form.Group controlId="exampleForm.ControlInput2">
              <Form.Label>Votre adresse e-mail </Form.Label>
              <Form.Control
                type="email"
                placeholder="Ex : nadine.comblat@gmail.com"
                required
                name="email"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col className="p-2">
            <Form.Group controlId="exampleForm.ControlTextarea1">
              {/* <Form.Label>Message </Form.Label> */}
              <Form.Control
                type="text"
                as="textarea"
                rows="6"
                placeholder={placeholerMsg}
                name="Message"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="ml-auto p-2 mt-4">
          {status === "SUCCESS" ? (
            <p className="onTheRight">Message Bien Envoyé 😉</p>
          ) : (
            <Button className="onTheRight" variant="primary" type="submit">
              Envoyer
            </Button>
          )}
          {status === "ERROR" && (
            <p className="onTheRight">Echec de l'envoi 😢</p>
          )}
        </Row>
      </form>
    )
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }
}
