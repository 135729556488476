import React from "react"
import Layout from "../components/layout"

import SEO from "../components/seo"
import Contact from "../containers/contact"
import OuSuisJe from "../containers/ou-suis-je"


const ContactPage = () => (
  
  <Layout>
    <SEO title="Contact" />
    <br/><br/>
    <OuSuisJe/>
    <Contact/>
  </Layout>
 
)

export default ContactPage
