import React from "react"
import Bandeau from "../components/bandeau"

import Container from 'react-bootstrap/Container'
import TestForm from "./form"

const Contact = () => (
  
  <Bandeau title="Me joindre">
      <Container>
      <TestForm></TestForm>
      </Container>
    </Bandeau>
 
)

export default Contact

